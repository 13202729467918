import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

Vue.use(Vuex);

const state = {
  mostrarBotonVolver: false,
  textoCabecera: "Democenter",
  switches: {
    mostrarTiposDePago: false,
    mostrarTodosLosParametros: false,
    mostrarDocumentacion: false,
    mostrarJSON: false,
    mostrarJSONCallback: false,
    mostrarWidget: false,
    widgetIsLoading: false,
  },
  parametros: {
    PSD2: {
      id: "",
      action: "payment",
      countryCode: "ALL",
      banksShown: "ALL",
      banksShownPSD2: "ALL",
      banksShownV3: "ALL",
      defaultBank: "",
      autoStart: 0,
      defaultLanguage: "ES",
      showSandbox: 1,
      showSplashScreen: 0,
      showCheckTerms: 1,
      URLredirectAfterOK: "",
      URLredirectAfterKO: "",
      dailyFrequency: 4,
      validUntil: "16-04-2022",
      paymentType: "normal",
      amount: 0.1,
      currency: "EUR",
      sourceIBAN: "",
      sourceCreditorName: "",
      destinationIBAN: "",
      destinationCreditorName: "Cruz Roja",
      paymentDescription: "",
      firstQuotaDay: "",
      frequency: "",
      numberOfQuotas: "",
    },
  },
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {},
});
