import * as types from "./mutations_types";
import $ from "jquery";

export default {
  [types.SET_MOSTRAR_BOTON_VOLVER](state, mostrarBotonVolver) {
    state.mostrarBotonVolver = mostrarBotonVolver;
  },
  [types.SET_TEXTO_CABECERA](state, textoCabecera) {
    state.textoCabecera = textoCabecera;
  },
  [types.RESET_PARAMETROS_PSD2](state) {
    state.parametros.PSD2 = {
      id: "",
      action: "payment",
      countryCode: "ALL",
      banksShown: "ALL",
      banksShownPSD2: "ALL",
      banksShownV3: "ALL",
      defaultBank: "",
      autoStart: 0,
      defaultLanguage: "ES",
      showSandbox: 1,
      showSplashScreen: 0,
      showCheckTerms: 1,
      URLredirectAfterOK: "",
      URLredirectAfterKO: "",
      dailyFrequency: 4,
      validUntil: "16-04-2022",
      paymentType: "normal",
      amount: 0.1,
      currency: "EUR",
      sourceIBAN: "",
      sourceCreditorName: "",
      destinationIBAN: "",
      destinationCreditorName: "Cruz Roja",
      paymentDescription: "",
      firstQuotaDay: "",
      frequency: "",
      numberOfQuotas: "",
    };
  },
  [types.SET_PARAMETRO_PSD2](state, config) {
    let numero = Number(config.value);
    let value = numero;
    if (isNaN(numero)) {
      value = config.value;
    }
    state.parametros.PSD2[config.target] = value;
  },
  [types.REMOVE_PARAMETRO_PSD2](state, target) {
    delete state.parametros.PSD2[target];
  },
  [types.SET_SWITCH](state, config) {
    switch (config.target) {
      case "mostrarTiposDePago":
        if (config.value == true) {
          state.switches["mostrarDocumentacion"] = false;
          state.switches["mostrarJSON"] = false;
        }
        break;
      case "mostrarDocumentacion":
        if (config.value == true) {
          state.switches["mostrarTiposDePago"] = false;
          state.switches["mostrarJSON"] = false;
        }
        break;
      case "mostrarJSON":
        if (config.value == true) {
          state.switches["mostrarTiposDePago"] = false;
          state.switches["mostrarDocumentacion"] = false;
        }
        break;

      default:
        break;
    }
    state.switches[config.target] = config.value;
  },
  [types.START_SCROLL]() {
    setTimeout(() => {
      let h = $("#nav")[0].offsetHeight;
      //   console.log("SCROLL -> " + h);
      window.scrollTo(0, h);
    }, 15);
  },
};
