<template>
  <div class="home">
    <div class="d-flex flex-column justify-content-evenly top-ab">
      <ZonaLinks
        title="Agregación"
        :showTitle="true"
        :links="linksAgregacion"
      />
      <ZonaLinks title="Pagos" :showTitle="true" :links="linksPagos" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ZonaLinks from "@/components/Home/ZonaLinks.vue";
import linksHome from "@/api/links/home.json";

export default {
  name: "Home",
  components: {
    ZonaLinks,
  },
  data() {
    return {
      linksAgregacion: linksHome.linksAgregacion,
      linksPagos: linksHome.linksPagos,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-ab {
  padding: 2rem;
}
</style>
