<template>
  <div class="hello">
    <b-card title-tag="h1" :title="getTitle()" class="border-0">
      <div class="d-flex flex-wrap justify-content-evenly mt-4">
        <b-card
          v-for="(link, index) in this.links"
          :key="index"
          :link="link"
          no-body
          v-bind:class="{
            cardBodyActiveAP: link.disponible,
            cardBodyDisabledAP: !link.disponible,
          }"
          class="overflow-hidden cardBodyAP align-items-center"
          @click="goTo(link.linkTo, link.linkType, link.disponible)"
        >
          <b-row no-gutters class="h-100 w-100 align-items-center">
            <b-col md="6">
              <b-card-img
                :src="getRutaImg(link.imgRuta, link.img)"
                :alt="link.nombre"
                class="rounded-0 mx-3"
              ></b-card-img>
            </b-col>
            <b-col md="6">
              <b-card-body :title="link.nombre">
                <b-card-text>
                  {{ link.resumen }}
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ZonaLinks",
  props: {
    title: String,
    showTitle: Boolean,
    links: Array,
  },
  methods: {
    getTitle: function () {
      if (this.showTitle) {
        return this.title;
      } else {
        return "";
      }
    },
    goTo: function (link, type, disponible) {
      if (disponible) {
        if (type == "interno") {
          this.$router.push(link);
        } else {
          window.open(link);
        }
      }
    },
    getRutaImg: function (imgRuta, img) {
      if (imgRuta == "iconos/productos") {
        return require("../../assets/img/iconos/productos/" + img);
      } else if (imgRuta == "iconos/general") {
        return require("../../assets/img/iconos/general/" + img);
      } else {
        return require("../../assets/img/clientes/" + img);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cardBodyAP {
  width: 400px;
  height: 188px;
  margin: 2rem;
}
.cardBodyActiveAP {
  cursor: pointer;
}
.cardBodyDisabledAP {
  cursor: not-allowed;
}
.cardBodyActiveAP:hover {
  background-color: #fff6cf;
}
.cardBodyDisabledAP:hover {
  background-color: #efefef;
}
</style>
