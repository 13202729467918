import * as types from "./mutations_types";

export default {
  setConfigCabecera({ commit }, config) {
    commit(types.SET_MOSTRAR_BOTON_VOLVER, config.botonVolver);
    commit(types.SET_TEXTO_CABECERA, config.textoCabecera);
  },
  resetParametrosPSD2({ commit }) {
    commit(types.RESET_PARAMETROS_PSD2);
  },
  setParametroPSD2({ commit }, config) {
    commit(types.SET_PARAMETRO_PSD2, config);
  },
  removeParametroPSD2({ commit }, config) {
    commit(types.REMOVE_PARAMETRO_PSD2, config);
  },
  setSwitch({ commit }, config) {
    commit(types.SET_SWITCH, config);
  },
  startScroll({ commit }) {
    commit(types.START_SCROLL);
  },
};
