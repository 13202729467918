import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/agregacionFinanciera",
    name: "AgregacionFinanciera",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/sections/AgregacionFinanciera.vue"
      ),
  },
  {
    path: "/agregacionNoFinanciera",
    name: "AgregacionNoFinanciera",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/sections/AgregacionNoFinanciera.vue"
      ),
  },
  {
    path: "/agregacionCasos",
    name: "AgregacionCasos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/sections/AgregacionCasos.vue"
      ),
  },
  {
    path: "/pagosPSD2",
    name: "PagosPSD2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/widgets/PagosPSD2.vue"),
  },
  {
    path: "/pagosCasos",
    name: "PagosCasos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/sections/PagosCasos.vue"
      ),
  },
  {
    path: "/agregacionFinanciera/mixta",
    name: "AgregacionFinancieraMixta",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/widgets/AgregacionFinancieraMixta.vue"
      ),
  },
  {
    path: "/agregacionCasos/scoring",
    name: "Scoring",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/widgets/Scoring.vue"),
  },
  {
    path: "/agregacionCasos/scoring/lectura",
    name: "Scoring Lectura",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/widgets/ScoringLectura.vue"
      ),
  },
  {
    path: "/agregacionCasos/scoring/resultado",
    name: "Scoring Resultado",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/widgets/ScoringResultado.vue"
      ),
  },
  {
    path: "/agregacionCasos/vodafone",
    name: "Vodafone",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/widgets/Vodafone.vue"),
  },
  {
    path: "/agregacionCasos/vodafone/lectura",
    name: "Vodafone Lectura",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/widgets/VodafoneLectura.vue"
      ),
  },
  {
    path: "/agregacionCasos/vodafone/resultado",
    name: "Vodafone Resultado",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/widgets/VodafoneResultado.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
