export default {
  getMostrarBotonVolver(state) {
    return state.mostrarBotonVolver;
  },
  getTextoCabecera(state) {
    return state.textoCabecera;
  },
  getParametrosPSD2(state) {
    return state.parametros.PSD2;
  },
  getSwitches(state) {
    return state.switches;
  },
};
