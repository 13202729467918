<template>
  <div id="app">
    <div id="nav">
      <div class="d-flex justify-content-evenly top-ab align-items-center">
        <img
          class=""
          style="height: 4rem"
          alt="Afterbanks. Logo"
          src="./assets/img/afterbanks/logo_afterbanks_web.min.svg"
        />
        <div class="d-flex flex-column">
          <h1 class="m-0" style="line-height: 5rem">{{ textoCabecera }}</h1>
          <h6
            v-if="mostrarBotonVolver"
            class="m-0"
            style="line-height: 2rem"
            @click="$router.push('/')"
          >
            Volver
          </h6>
        </div>
        <img
          class="mt-3"
          style="height: 3.2rem"
          alt="Arcopay. Logo"
          src="./assets/img/arcopay/logo_arcopay_web.min.svg"
        />
      </div>
    </div>
    <!-- VISTAS -->
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "App",
  mounted() {
    this.setConfigCabecera({
      botonVolver: false,
      textoCabecera: "Democenter",
    });
  },
  updated() {
    if (this.$route.path == "/") {
      this.setConfigCabecera({
        botonVolver: false,
        textoCabecera: "Democenter",
      });
    }
  },
  computed: {
    ...mapGetters({
      mostrarBotonVolver: "getMostrarBotonVolver",
      textoCabecera: "getTextoCabecera",
    }),
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
    }),
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/montserrat-v14-latin-regular.woff2") format("woff2");
  font-style: normal;
}

#app {
  font-family: Montserrat-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

#nav {
  padding: 30px;
  background-color: #ffe424;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h6 {
  cursor: pointer;
}
h6:hover {
  font-weight: 800;
}
</style>
